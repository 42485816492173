import React from 'react'
import GoogleMapReact from 'google-map-react'
// Components
import { Seo, PageHeader } from '../components'
// Context
import { usePrismic } from '../context/PrismicContext'
import { useAuthContext } from '../context/AuthContext'
// Styles
import { Container, StyledSpan, Wrapper } from '../styles/common'
// Utils
import environment from '../utils/environment'

const ContactUsPage = () => {
  const {
    prismicData: {
      prismicSideMenu: { chat },
      prismicContactUsPage: {
        page_title,
        contact_us,
        page_description,
        contact_info,
        us_phone,
        international_phone,
        fax,
        email,
        address,
        office_address,
        service_hours_title,
        service_hours,
        call_hours_title,
        call_hours,
      },
    },
  } = usePrismic()

  const { qUser } = useAuthContext()

  const mapMarker = {
    lat: 40.36,
    lng: -111.76,
  }

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: mapMarker,
      map,
      title: 'Q Sciences Inc',
    })

    return marker
  }

  return (
    <>
      <Seo title={page_title[0].text} />
      <Wrapper>
        <PageHeader exitRoute="/">{contact_us[0].text}</PageHeader>
        <Container>
          <Container align="center">
            {page_description.map(description => (
              <StyledSpan key={description.text}>{description.text}</StyledSpan>
            ))}
          </Container>
          <Container header>{contact_info[0].text}</Container>
          <Container>
            <Container row justify="center">
              <StyledSpan rowHeader>{us_phone[0].text}:</StyledSpan>
              <StyledSpan>{us_phone[1].text}</StyledSpan>
            </Container>
            <Container row justify="center">
              <StyledSpan rowHeader>{international_phone[0].text}:</StyledSpan>
              <StyledSpan>{international_phone[1].text}</StyledSpan>
            </Container>
            <Container row justify="center">
              <StyledSpan rowHeader>{fax[0].text}:</StyledSpan>
              <StyledSpan>{fax[1].text}</StyledSpan>
            </Container>
            <Container row justify="center">
              <StyledSpan rowHeader>{email[0].text}:</StyledSpan>
              <StyledSpan>
                <a href={`mailto:${email[1].text}`}>{email[1].text}</a>
              </StyledSpan>
            </Container>
            {qUser ? (
              <Container row justify="center">
                <StyledSpan rowHeader>{chat}:</StyledSpan>
                <StyledSpan>
                  <a href="https://info.qsciences.com/chat" target="_blank">
                    https://info.qsciences.com/chat
                  </a>
                </StyledSpan>
              </Container>
            ) : null}
          </Container>
          <Container header>{address[0].text}</Container>
          <Container align="center">
            {office_address.map(item => (
              <StyledSpan key={item.text}>{item.text}</StyledSpan>
            ))}
          </Container>
          <Container header>{service_hours_title[0].text}</Container>
          <Container align="center">
            {service_hours.map(item => (
              <StyledSpan key={item.text}>{item.text}</StyledSpan>
            ))}
          </Container>
          <Container header>{call_hours_title[0].text}</Container>
          <Container align="center">
            {call_hours.map(item => (
              <StyledSpan key={item.text}>{item.text}</StyledSpan>
            ))}
          </Container>
          <div
            style={{
              width: '100%',
              height: '100vw',
              maxHeight: '600px',
              marginTop: '2em',
              marginBottom: '2em',
            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: environment.GOOGLE_MAP_API_KEY,
              }}
              defaultCenter={mapMarker}
              defaultZoom={11}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            />
          </div>
        </Container>
      </Wrapper>
    </>
  )
}

export default ContactUsPage
